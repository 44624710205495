<template>
  <div>
    <el-card>
      <div slot="header">
        <span style="color: #409EFF; font-size: 16px;">系 统 运 行 状 态</span>
      </div>
      <div style="font-size: 14px; color: rgba(84,82,82,0.76); height: 449px">
        <div>
          本地服务器状态：
          <el-tag :type="datas.type">{{datas.msg}}</el-tag>
        </div>
        <div style="margin-bottom: 15px; margin-top: 15px">
          后台运行状态：：
          <el-tag :type="datas.type">{{datas.msg}}</el-tag>
        </div>
        <div>
          数据库状态：
          <el-tag :type="datas.type">{{datas.msg}}</el-tag>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "system",
  data() {
    return {
      datas: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        method: 'get',
        url: '/test.json',
        data: {}
      }).then((res) => {
        this.datas = res.data,
        console.log('ok')
      }).catch((err) => {
        this.datas = {type: 'danger', msg: '暂未获取'}
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>